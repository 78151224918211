import React from 'react';
import { BsTwitter, BsInstagram, BsLinkedin } from 'react-icons/bs';
const SocialMedia = () => {
  return (
    <div className="app__social">
        <div>
            <a href="https://twitter.com/PedroRa15462488">
              <BsTwitter href="https://twitter.com/PedroRa15462488" />
            </a>
        </div>
        <div>
            <BsInstagram />
        </div>
        <div>
            <BsLinkedin />
        </div>    
    </div>
 
  )
}

export default SocialMedia